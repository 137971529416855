.achievment_base_design{
    padding-block: 1rem;
    padding-inline: 10%;
}

.individual_achievment_parent{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.individual_achievment_parent_reverse{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
}

.achievment_title{
    font-weight: 700;
    font-size: 1.5rem;
}

.achievment_image{
    width: 30rem;
    height: 20rem;
}