.Home-parent{
    padding-inline: 10%;
    display: flex;
    flex-direction: column;
    gap: 8rem;
}

.custom_title{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0%;
    margin: 0%;
}

.custom_text{
    font-size: 30px;
    padding: 0%;
    margin: 0%;
}