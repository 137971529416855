.HeadLine{
    color: white;
    font-size: 4rem;
    font-family: "Space Mono", monospace;
    margin-bottom: 1rem;
    cursor: default;
}

.HeroParent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100vh;
}

.PlaceHolder_Image{
    color: white;
    width: 50%;
}

.HeroChild-UserInfo{
    width: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}