.Card-parent{
    width: 250px;
    height: 400px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px white solid;
    border-radius: 1%;
    padding: 1.5rem;
    cursor: pointer;
}

.Card-Image{
    width: 200px;
}

.Card-Summary{
    font-size: 15px;
    text-align: center;
}

.swiper-slide{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}