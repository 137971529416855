.blog {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card {
    border: 1px solid #0f0;
    margin: 10px;
    padding: 10px;
    width: 80%;
  }
  
  .card h2 {
    margin: 0;
  }
  
  .card a {
    color: #0f0;
  }
  