.CardDeck-parent{
    padding-block: 3rem;
    display: flex;
    justify-content: center;
    gap: 2.5rem;
    flex-wrap: wrap;
}

.custom_title{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0%;
    margin: 0%;
}

.custom_text{
    font-size: 30px;
    padding: 0%;
    margin: 0%;
}